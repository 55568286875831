import fetch from "@/utils/service";
// 人大代表
export function getDeputiesList(params) {
  return fetch({
    url: "/v1.0/api/deputies/list",
    params: params,
  });
}
// 党员名录
export function getPartyMemberList(params) {
  return fetch({
    url: "/v1.0/api/party_member/list",
    params: params,
  });
}
// 大学生
export function getStudentList(params) {
  return fetch.post("/v1.0/api/student/getStudents", params);
}
// 献资
export function getRank(params) {
  return fetch.post("/v1.0/api/honor/get_honors_rank", params);
}
// 长寿老人
export function getLongevityList(params) {
  return fetch.post("/v1.0/api/longevity/getLongevitys", params);
}
export function getFamousList(params) {
  return fetch.post("/v1.0/api/famous/getFamousPerson", params);
}
// 团体组织
export function getGroupList(params) {
  return fetch({
    url: "/v1.0/api/village/get_group_list",
    params: params,
  });
}