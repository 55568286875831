<template>
  <dv-full-screen-container class="bg">
    <!-- 顶部 -->
    <Header
      pageNum="5/7"
      :villageName="detail.village_name"
      :slogan="detail.slogan"
      :curCountDown="curCountDown"
      @onShowNext="onShowNext"
    />
    <!-- <div class="header">
      <div class="header__left">百川汇海 胜在齐心</div>
      <div class="header__title">
        <div>乡村振兴 数字</div>
        <div class="name">百胜</div>
      </div>
      <div class="header__right">
        翻页倒计时 {{ curCountDown }}s
        <span class="header__page">5/7</span>
      </div>
    </div> -->
    <div class="main">
      <div class="m__part1">
        <div class="m__part1-top">
          <div class="m__title">人大代表 TOP5/{{ deputyCount }}</div>
          <div>
            <dv-scroll-board :config="deputy" class="pt1__list" />
          </div>
        </div>
        <div class="m__part1-bottom">
          <div class="m__title">党员名录 TOP10/{{ memberCount }}</div>
          <div>
            <dv-scroll-board :config="member" class="pb1__list" />
          </div>
        </div>
      </div>
      <div class="m__part2">
        <div class="m__title">大学生 TOP15/{{ studentCount }}</div>
        <div>
          <dv-scroll-board :config="student" class="p2__list" />
        </div>
      </div>
      <div class="m__part3">
        <div class="m__part3-top">
          <div class="m__title">献资榜 TOP10</div>
          <div>
            <dv-scroll-board :config="rank" class="pt3__list" />
          </div>
        </div>
        <div class="m__part3-bottom">
          <div class="m__title">长寿名录 TOP5/{{ longCount }}</div>
          <div>
            <dv-scroll-board :config="long" class="pb3__list" />
          </div>
        </div>
      </div>
      <div class="m__part4">
        <div class="m__part4-top">
          <div class="m__title">名人 TOP5/39</div>
          <vueSeamlessScroll
            class="seamless-warp"
            :data="famousList"
            :class-option="defaultOption"
          >
            <div v-for="(item, i) of famousList" :key="i">
              <div class="p4-title f-j-sb">
                {{ item.name }}({{ item.birthday }})<span class="NO"
                  >NO.{{ item.index }}</span
                >
              </div>
              <div class="p4-instr content" v-html="item.introduction"></div>
            </div>
          </vueSeamlessScroll>
        </div>
        <div class="m__part4-bottom">
          <div class="m__title">团体组织 TOP3/{{ groupCount }}</div>
          <template v-if="groupList.length">
            <vueSeamlessScroll
              class="seamless-warp"
              :data="groupList"
              :class-option="defaultOption"
            >
              <div v-for="(item, i) of groupList" :key="i">
                <div class="p4-title">{{ item.title }}</div>
                <!-- <div class="p4-instr">
                  {{ item.introduction }}
                </div> -->
                <div class="p4-instr content" v-html="item.introduction"></div>
              </div>
            </vueSeamlessScroll>
          </template>
          <template v-else>
            <div class="p4-title">乡贤帮帮团</div>
            <div class="p4-instr">
              原籍邻村关头林氏人，年轻时嫁与本村池嘉盛为妻。不幸池嘉盛三十四岁病逝，林氏青年孀守，贞节至终。林氏为人聪明伶俐，精明强记。据池氏族谱记载:林氏嫁，与池嘉盛时，家境窘迫无立锥之地，而她性格刚强，决计自立，筹措资金，雇人从事养殖缢蛏和渔业生产，到老富有万金，且慷慨解囊,热心公益,不居人后。道光15年(1836年),本村建造‘“圣王宫”
              ，她一-次捐银七十两，名列全村之首(有碑文可据)。她的勤劳和贞节事迹,经层层呈报至朝廷，清道光皇帝曾下旨建贞节坊于原大智寺的东侧，并立有碑记。惜1957年修建观音亭水库时，与大智寺一起均为水库淹没,牌坊废圮，碑石已失。
            </div>
          </template>
        </div>
      </div>
      <div class="code f-ai-c">
        <div class="f-1">
          <div class="txt f-ai-c">
            <img class="view" src="../assets/industry/查看@2x.png" alt="" />
            {{ config.follow_count }}人
          </div>
          <div class="txt f-ai-c mt-10">
            <img class="click" src="../assets/industry/点击@2x.png" alt="" />
            {{ config.visit_count || 0 }}次
          </div>
        </div>
        <img class="icon" :src="config.qrcode_img" />
        <!-- <img class="icon" src="../assets/industry/小程序@2x.png" alt="" /> -->
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import {
  getDeputiesList,
  getPartyMemberList,
  getStudentList,
  getRank,
  getLongevityList,
  getFamousList,
  getGroupList,
} from "../api/character";
import { getConfig } from "@/api/village";
import { formatDate, filterAge } from "../utils/format.js";
import { getHomepage } from "@/api/survey/index";
import vueSeamlessScroll from "vue-seamless-scroll";
import Header from "./components/Header";
export default {
  data() {
    return {
      deputyList: [],
      deputyCount: 0,
      memberList: [],
      memberCount: 0,
      studentList: [],
      studentCount: 0,
      rankList: [],
      rankCount: 0,
      longList: [],
      longCount: 0,
      famousList: [],
      famousCount: [],
      curCountDown: 60,
      interval: null,
      groupList: [],
      groupCount: 0,
      id: "",
      config: {},
      detail: {},
      defaultDetail: {
        slogan: "百川汇海 胜在齐心",
        village_name: "百胜",
      },
    };
  },
  components: {
    vueSeamlessScroll,
    Header,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    deputy() {
      return {
        header: ["姓名", "级别", "届数"],
        data: this.deputyList,
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center", "center"],
      };
    },
    member() {
      return {
        header: ["姓名", "年龄", "党龄"],
        data: this.memberList,
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center", "center"],
        rowNum: 10,
      };
    },
    student() {
      return {
        header: ["姓名", "学历", "大学", "现龄"],
        data: this.studentList,
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center", "center", "center"],
        rowNum: 15,
        columnWidth: [140, 140, 300, 80],
      };
    },
    rank() {
      return {
        header: ["姓名", "金额"],
        data: this.rankList,
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center"],
        rowNum: 10,
        columnWidth: [110],
      };
    },
    long() {
      return {
        header: ["姓名", "年龄"],
        data: this.longList,
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center"],
      };
    },
  },
  mounted() {
    const id = this.$route.query.village_id || "6";
    this.id = id;
    this.getHomepage(id);
    this.getConfig(id);
    this.getDeputies();
    this.getMemberList();
    this.getStudentList();
    this.getRankList();
    this.getLongList();
    this.getFamousList();
    this.getGroupList();
  },
  methods: {
    getHomepage(id) {
      getHomepage({ village_id: id }).then((res) => {
        console.log(res);
        this.detail = res.data.data || this.defaultDetail;
      });
    },
    setTimer(time) {
      let that = this;
      that.curCountDown = time || 60;
      this.interval = setInterval(function () {
        that.curCountDown = that.curCountDown - 1;
        if (that.curCountDown <= 0) {
          clearInterval(that.interval);
          that.$router.push({
            path: "village",
            query: { village_id: that.id },
          });
        }
      }, 1000);
    },
    onShowNext() {
      clearInterval(this.interval);
      this.$router.push({
        path: "village",
        query: { village_id: this.id },
      });
    },
    async getConfig(id) {
      const { data } = await getConfig({
        village_id: id,
      });
      if (data.code === "200") {
        this.config = data.data;
        this.setTimer(data.data.countdown);
      }
      console.log("data", data);
    },
    async getDeputies() {
      const { data } = await getDeputiesList({
        village_id: this.id,
        category: "",
        page: 1,
        rows: 5,
      });
      console.log(data.data);
      const { pagedata, totalcount } = data.data;
      this.deputyCount = totalcount;
      pagedata.forEach((item) => {
        const arr = [
          item.name,
          item.level === 1
            ? "全国"
            : item.level === 2
            ? "省级"
            : item.level === 3
            ? "市级"
            : item.level == 4
            ? "县级"
            : "镇级",
          item.class.toString(),
        ];
        this.deputyList.push(arr);
      });
    },
    async getMemberList() {
      const { data } = await getPartyMemberList({
        village_id: this.id,
        category: "",
        sort: "join_time",
        page: 1,
        rows: 10,
      });
      const { pagedata, totalcount } = data.data || [];
      this.memberCount = totalcount;
      pagedata.forEach((item) => {
        const arr = [
          item.member_name,
          filterAge(item.birthday, new Date().getTime()),
          item.party_age.toString(),
        ];
        this.memberList.push(arr);
      });
    },
    async getStudentList() {
      const { data } = await getStudentList({
        village_id: this.id,
        sort: 2,
        page: 1,
        rows: 15,
      });

      const { pagedata, totalcount } = data.data || [];
      this.studentCount = totalcount;
      pagedata.forEach((item) => {
        const arr = [
          item.name,
          item.education,
          item.college,
          filterAge(item.birthday, new Date().getTime()),
        ];
        this.studentList.push(arr);
      });
    },
    async getRankList() {
      const { data } = await getRank({
        village_id: this.id,
        page: 1,
        rows: 10,
      });
      const { pagedata, totalcount } = data.data || [];
      this.rankCount = totalcount;
      pagedata.forEach((item) => {
        const arr = [item.name, item.total];
        this.rankList.push(arr);
      });
    },
    async getLongList() {
      const { data } = await getLongevityList({
        village_id: this.id,
        range: 0,
        sort: 2,
        name: "",
        page: 1,
        rows: 5,
        sortType: 0,
      });
      // const pagedate = [{ name: "111", birthday: 1964 }];
      const { pagedata, totalcount } = data.data || [];
      this.longCount = totalcount;
      pagedata.forEach((item) => {
        const arr = [item.name, filterAge(item.birthday, new Date().getTime())];
        this.longList.push(arr);
      });
    },
    async getFamousList() {
      const { data } = await getFamousList({
        village_id: this.id,
        sort: "birthday",
        tag: "",
        page: 1,
        rows: 10,
      });
      const { pagedata, totalcount } = data.data || [];
      this.famousCount = totalcount;
      this.famousList = pagedata;
    },
    async getGroupList() {
      const { data } = await getGroupList({
        village_id: this.id,
      });
      console.log(data);
      this.groupList = data.data;
      this.groupCount = data.data.length;
    },
  },
};
</script>
<style scoped>
.dv-scroll-board .header .header-item {
  color: #56fefe;
  font-size: 18px;
}
.dv-scroll-board .rows .row-item {
  font-size: 18px;
  height: h(40) !important;
  line-height: h(40) !important;
}
</style>
<style lang="scss" scoped>
.bg {
  background: #040b13;
  color: #ffffff;
}

.main {
  position: relative;
  padding: h(25) 30px;
  display: flex;
  .m__title {
    width: 100%;
    height: h(38);
    line-height: h(30);
    color: #44ffff;
    font-size: 22px;
    background: url("../assets/character/Vector 66备份 7@2x.png") no-repeat;
    background-size: 100% h(4);
    background-position: center bottom;
  }
  .m__part1 {
    width: 404px;
    &-top {
      width: 100%;
      height: h(366);
      background: url("../assets/character/人大代表外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 25px;
    }
    &-bottom {
      width: 100%;
      height: h(547);
      background: url("../assets/character/党员名录外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
    }
    .pt1__list {
      width: 100%;
      height: h(270) !important;
      margin-top: 10px;
      overflow: hidden;
    }
    .pb1__list {
      width: 100%;
      height: h(450) !important;
      margin-top: 10px;
      overflow: hidden;
    }
  }
  .m__part2 {
    width: 561px;
    height: h(931);
    background: url("../assets/character/大学生外框@2x.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 18px;
    padding: h(21) 25px;
    .p2__list {
      width: 100%;
      height: h(840) !important;
      margin-top: 10px;
      overflow: hidden;
    }
  }
  .m__part3 {
    width: 310px;
    margin-left: 18px;
    &-top {
      width: 100%;
      height: h(556);
      background: url("../assets/character/献资榜外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 25px;
      .pt3__list {
        width: 100%;
        height: h(470) !important;
        margin-top: 10px;
        overflow: hidden;
      }
    }
    &-bottom {
      width: 100%;
      height: h(358);
      background: url("../assets/character/长寿榜外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
      .pb3__list {
        width: 100%;
        height: h(270) !important;
        margin-top: 10px;
        overflow: hidden;
      }
    }
  }
  .m__part4 {
    width: 544px;
    margin-left: 18px;
    &-top {
      width: 100%;
      height: h(457);
      background: url("../assets/character/名人外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 25px;
    }
    &-bottom {
      width: 100%;
      height: h(457);
      background: url("../assets/character/名人外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
    }
  }
  .p4-title {
    font-size: 22px;
    padding: h(20) 0 10px;
    font-weight: 600;
  }
  .NO {
    font-style: normal !important;
  }
  .p4-instr {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
  }
  .code {
    position: absolute;
    width: 242px;
    height: h(130);
    padding: h(25) 20px;
    background: url("../assets/industry/浮窗_底板@2x.png");
    background-size: 100% 100%;
    right: 0;
    bottom: h(26);
    .txt {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      .view {
        width: 24px;
        height: h(17);
        margin-right: 10px;
      }
      .click {
        width: 24px;
        height: h(24);
        margin-right: 10px;
      }
    }
    .icon {
      width: 94px;
      height: h(94);
    }
  }
}
.seamless-warp {
  width: 100%;
  height: h(360);
  margin-top: h(10);
  overflow: hidden;
}
.content {
  & >>> p {
    padding: 0 !important;
    margin: 0 !important;
  }
  & >>> span {
    font-family: "SourceHanSansCN, -apple-system-font, PingFang SC, Microsoft YaHei, sans-serif" !important;
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
  }
}
</style>
